.event-page-title {
  text-align: center;
  margin-top: 70px;
}

/* search function, date picker, & cal/list toggle  */

  /* .search-controls-container {
    display: flex; 
    align-items: center; 
    justify-content: space-between;
    position: fixed; 
    height: 30px;
    top: 0; 
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #ffffff; 
    padding: 8px 8px; 
    margin-top: 65px;
    margin-left: 120px;
    width: 80%;
    z-index: 999;
  } */

  /* .search-bar {
    width: 70%;
    height: 30px;
    border: none;
    margin-right: 10px;
    padding: 8px;
    font-size: 1.2em; 
    display: block; 
  } */

  /* .button-controls-container {
    background-color: #ffffff;
    justify-content: space-evenly;
  } */

  /* .search-container-button {
    padding: 10px 10px;
    align-content: center;
    border: none;
    background-color: #444444;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 7px;
    margin-right: 4px;
  } */

/* EventList */

.event-list {
    display: block;
    justify-content: center;
    align-items: center;
    list-style-type: none; 
    padding: 0;
  }

.month-separator {
  display: flex; 
  justify-content: center;
}

.event-list-li {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
/* EventItem */

.event-item-container {
  display: flex; /* Use flexbox */
  width: 75%;
  align-items: center; /* Align items vertically */
  justify-content: space-between;
}

.event-item-text {
  text-align: left;
}

.event-name {
  font-size: 1.6em; 
}

.event-subtext {
  color: #444444;
}

.learn-more-button {
  padding: 9px;
  align-content: center;
  border: none;
  background-color: #444444;
  color: #ffffff;
  border-radius: 4px;
  margin-left: 7px;
  margin-right: 4px;
}

/* mobile */ 

@media (max-width: 500px) {
  .event-item-container {
    padding: 20px;
    display: block;
    align-items: center;
    justify-content: center;
  }
  .event-item-text {
    text-align: center;
  }
  .event-subtext {
    font-size: 15px;
    color: #444444;
  }
  .learn-more-button {
    width: 83px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
  }
};
