/* About.css */

.container {
    display: flex;
    margin-top: 15px;
    margin-bottom: 0px;
}
  
.text {
    flex: 1;
    padding: 30px;
}

.image {
    flex: 1;
    margin-top: 25px;
}

img {
    display: block;
    width: 100%;
    height: auto; /* Added to maintain aspect ratio */
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }
    .image {
        order: -1; /* Change order to display image first */
        margin-top: 0px;
        padding: 0px;
    }
    .text {
        padding: 0px 30px 30px 30px; /* Adjust padding to maintain spacing */
    }
}
