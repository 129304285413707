.background {
    background-image: url("../../../public/Open2019_BackBeginning.jpg");
    background-color: #444444;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
  }

.text-container {
    width: 50%;
    text-align: center; 
    font-size: 18px
}

.events-link {
    color: white;
    text-decoration: none; 
    margin: 5px; 
    padding: 10px 20px; 
    border-radius: 5px; 
    background-color: rgba(100, 100, 100, .7); 
    border: none; 
    cursor: pointer;
    transition: background-color 0.3s ease; 
}
 
@media (max-width: 768px) {
    .text-container {
        width: 75%;
    }
}