/* PressKit.css */

.page-title {
    text-align: center;
    margin-top: 30px;
}

/* Style for the entire press kit page */
.press-kit-container {    
    margin: 0 auto;
    margin-top: 30px;
    padding: 20px;
    background-color: #272727;
    color: #f0f0f0;
  }
  
  /* Style for the section titles */
  .press-kit-section-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  /* Style for the downloadable image grid */
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    padding: 50px;
  }
  
  /* Style for individual downloadable images */
  .image-grid img {
    width: 100%;
    height: auto;
  }
  
  /* Style for the short and long bio sections */
  .bio-section {
    padding: 10px 50px;
  }
  
  /* Style for the short and long bio text */
  .bio-text {
    font-size: 16px;
    line-height: 1.5;
  }

@media (max-width: 480px) {
    .image-grid {
        padding: 0px;
    }
    .bio-section {
        padding: 10px 20px;
    }
}
  